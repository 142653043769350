/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { usePublicConfig } from "../../hooks/usePublicConfig";

const HelpCrunchProvider = ({ children }) => {
  const { user } = useAuth();
  const { env } = usePublicConfig();

  useEffect(() => {
    if (user?.id) {
      window.helpcrunchSettings = {
        organization: "cart2cart",
        appId: env?.helpcrunch_app_id ?? process.env?.REACT_APP_HELP_CRUNCH_ID,
        user: {
          user_id: user?.helpcrunch_user_hash,
          name: user?.firstname,
          email: user?.email,
        },
      };

      if (typeof HelpCrunch !== "undefined") {
        HelpCrunch("userAuth", {
          user_id: user?.helpcrunch_user_hash,
        });
      }
    }
  }, [user?.id]);
  return <>{children}</>;
};

export default HelpCrunchProvider;
