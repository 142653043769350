import { createSlice } from "@reduxjs/toolkit";

import { wizardCreateService } from "./wizardCreateService";
import { changeEmailsData } from "../../utils";

const initialState = {
  migration: null,
  firstFromTo: "",
  fromTo: {
    source: "",
    target: "",
    preview: false,
  },
  fromToParam: {
    prefix: "",
    route: "",
  },

  emptyMapping: false,
  emails: [],
  notificationData: null,
  globalNotification: "",
  accountSelectionStep: {
    emails: [],
    loading: false,
  },
  notice: {},
  demoVideo: null,
  additionalOptionsStep: {
    allOptions: null,
    allOptionsData: [],
  },
  stores: {
    source: [],
    target: [],
  },
  fields: {
    source: {},
    target: {},
  },
  storesConnect: {
    source: "",
    target: "",
  },
  skipConnection: {
    sourceSkipConnection: false,
    targetSkipConnection: false,
  },
  bridgeConnect: {
    source: false,
    target: false,
  },
  demoProgress: {
    entities: [],
    show_survey: false,
  },
};

export const wizardCreateSlice = createSlice({
  name: "wizard",
  initialState,

  reducers: {
    deleteMigrationId: (state) => {
      state.migration.id = "";
    },

    setEmptyMapping: (state, action) => {
      state.emptyMapping = action.payload;
    },

    setNotification: (state, action) => {
      state.notificationData = action.payload;
    },
    setGlobalNotification: (state, action) => {
      state.globalNotification = action.payload;
    },

    setFromTo: (state, action) => {
      state.fromTo = action.payload;
    },

    setFirstFromTo: (state, action) => {
      state.firstFromTo = action.payload;
    },

    setFromToParam: (state, action) => {
      state.fromToParam = action.payload;
    },

    setFromToParamRoute: (state, action) => {
      state.fromToParam.route = action.payload;
    },

    setNewTargetStore: (state, action) => {
      state.migration.target_store.cart = action.payload;
    },

    setNewSourceStore: (state, action) => {
      state.migration.source_store.cart = action.payload;
    },

    setBridgeConnect: (state, action) => {
      state.bridgeConnect[action.payload.type] = action.payload.connect;
    },

    setStoreConnect: (state, action) => {
      state.storesConnect[action.payload.type] = action.payload.connect;
    },

    setDefaultStatus: (state) => {
      state.migration.status = "created";
    },

    setTargetUrlReset: (state) => {
      state.migration.target_store.url = "";
    },

    setDemoVideo: (state, { payload }) => {
      state.demoVideo = payload;
    },

    setSourceSkipConnection: (state, action) => {
      state.skipConnection.sourceSkipConnection = action.payload;
    },

    setTargetSkipConnection: (state, action) => {
      state.skipConnection.targetSkipConnection = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      wizardCreateService.endpoints.getMigrationSession.matchFulfilled,
      (state, { payload }) => {
        state.migration = payload?.payload;
        state.demoProgress.entities = (
          payload?.payload?.entities.filter((entity) => entity.migrate) || []
        )?.map((item) => ({
          text: item.type,
          percent: 100,
        }));
      },
    );
    builder.addMatcher(
      wizardCreateService.endpoints.getNewMigrationSession.matchFulfilled,
      (state, { payload }) => {
        state.migration = payload?.payload;
      },
    );
    builder.addMatcher(
      wizardCreateService.endpoints.getMigrationSession.matchRejected,
      (state) => {
        state.migration = null;
      },
    );
    builder.addMatcher(
      wizardCreateService.endpoints.getNewMigrationSession.matchRejected,
      (state) => {
        state.migration = null;
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.createMigration.matchFulfilled,
      (state, { payload }) => {
        state.migration = null;
        state.migration = payload?.payload;
        state.additionalOptionsStep.allOptions = null;
        state.additionalOptionsStep.allOptionsData = [];
        state.demoProgress.entities = [];
        state.fields.source = {};
        state.fields.target = {};
        state.storesConnect.source = "";
        state.storesConnect.target = "";
        state.bridgeConnect.source = false;
        state.bridgeConnect.target = false;
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.createMigration.matchRejected,
      (state) => {
        state.migration = null;
        state.additionalOptionsStep.allOptions = null;
        state.additionalOptionsStep.allOptionsData = [];
        state.demoProgress.entities = [];
        state.fields.source = {};
        state.fields.target = {};
        state.storesConnect.source = "";
        state.storesConnect.target = "";
        state.bridgeConnect.source = false;
        state.bridgeConnect.target = false;
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.continueMigrationSession.matchFulfilled,
      (state, { payload }) => {
        state.migration = null;
        state.migration = payload?.payload;
        state.additionalOptionsStep.allOptions = null;
        state.additionalOptionsStep.allOptionsData = [];
        state.demoProgress.entities = [];
        state.fields.source = {};
        state.fields.target = {};
        state.storesConnect.source = "";
        state.storesConnect.target = "";
        state.bridgeConnect.source = false;
        state.bridgeConnect.target = false;
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.editMigrationSession.matchFulfilled,
      (state, { payload }) => {
        state.migration = null;
        state.migration = payload?.payload;
        state.additionalOptionsStep.allOptions = null;
        state.additionalOptionsStep.allOptionsData = [];
        state.demoProgress.entities = [];
        state.fields.source = {};
        state.fields.target = {};
        state.storesConnect.source = "";
        state.storesConnect.target = "";
        state.bridgeConnect.source = false;
        state.bridgeConnect.target = false;
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.getOptions.matchFulfilled,
      (state, payload) => {
        if (
          Object.keys(payload?.payload?.payload?.["data-notices"] || {})?.length
        ) {
          state.additionalOptionsStep.allOptions =
            payload?.payload?.payload?.["data-notices"];
        } else {
          state.additionalOptionsStep.allOptions = null;
        }

        if (payload?.payload?.payload) {
          state.additionalOptionsStep.allOptionsData = payload.payload.payload;
        } else {
          state.additionalOptionsStep.allOptionsData = [];
        }
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.setOptions.matchFulfilled,
      (state, payload) => {
        if (
          Object.keys(payload?.payload?.payload?.["data-notices"] || {})?.length
        ) {
          state.additionalOptionsStep.allOptions =
            payload?.payload?.payload?.["data-notices"];
        } else {
          state.additionalOptionsStep.allOptions = [];
        }

        if (payload?.payload?.payload) {
          state.additionalOptionsStep.allOptionsData = payload.payload.payload;
        } else {
          state.additionalOptionsStep.allOptionsData = [];
        }
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.getEmails.matchFulfilled,
      (state, { payload }) => {
        if (payload?.payload?.length) {
          state.accountSelectionStep.emails = changeEmailsData(
            payload?.payload,
          );
        }

        state.accountSelectionStep.loading = false;
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.getEmails.matchPending,
      (state) => {
        state.accountSelectionStep.loading = true;
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.getEmails.matchRejected,
      (state) => {
        state.accountSelectionStep.loading = false;
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.getAvailableStores.matchFulfilled,
      (state, { payload }) => {
        if (Object.values(payload?.payload?.source_carts || {})?.length) {
          state.stores.source = Object.values(
            payload?.payload?.source_carts || {},
          )
            .sort((a, b) => b.is_supported - a.is_supported)
            .map((store) => ({
              value: store?.id,
              label: store?.title,
              is_supported: store?.is_supported,
              cart_url: store?.cart_url,
            }));
        }

        if (Object.values(payload?.payload?.target_carts || {})?.length) {
          state.stores.target = Object.values(
            payload?.payload?.target_carts || {},
          )
            .sort((a, b) => b.is_supported - a.is_supported)
            .map((store) => ({
              value: store?.id,
              label: store?.title,
              is_supported: store?.is_supported,
              cart_url: store?.cart_url,
            }));
        }
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.checkTypeConnect.matchFulfilled,
      (state, { payload }) => {
        state.fields.source = payload?.payload?.fields?.source;
        state.fields.target = payload?.payload?.fields?.target;

        if (payload?.payload?.storetype === "source") {
          if (!state.storesConnect.source) {
            state.storesConnect.source =
              payload?.payload?.fields?.source?.sets?.default ||
              payload?.payload?.cartMigrationType;
          }
        }

        if (payload?.payload?.storetype === "target") {
          if (!state.storesConnect.target) {
            state.storesConnect.target =
              payload?.payload?.fields?.target?.sets?.default ||
              payload?.payload?.cartMigrationType;
          }
        }
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.setupStores.matchFulfilled,
      (state) => {
        state.storesConnect.source = "";
        state.storesConnect.target = "";
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.getDemoProgressEntities.matchFulfilled,
      (state, { payload }) => {
        state.demoProgress.entities = Object.keys(
          payload?.payload?.entities || {},
        )?.map((item) => ({
          name: item,
          percent: 100,
        }));
      },
    );

    builder.addMatcher(
      wizardCreateService.endpoints.getNoticesByStep.matchFulfilled,
      (state, { payload }) => {
        state.notice = payload?.payload;
      },
    );
  },
});

export const {
  deleteMigrationId,
  setBridgeConnect,
  setStoreConnect,
  setNotification,
  setNewTargetStore,
  setNewSourceStore,
  setFromTo,
  setDefaultStatus,
  setEmptyMapping,
  setFromToParam,
  setFirstFromTo,
  setFromToParamRoute,
  setTargetUrlReset,
  setGlobalNotification,
  setSourceSkipConnection,
  setTargetSkipConnection,
} = wizardCreateSlice.actions;

export const selectMigrationSession = (state) => state.wizard.migration;
export const selectBridgeConnect = (state) => state.wizard.bridgeConnect;
export const selectCartFields = (state) => state.wizard.fields;
export const selectNotice = (state) => state.wizard.notice;
export const selectDemoVideo = (state) => state.wizard.demoVideo;
export const selectStores = (state) => state.wizard.stores;
export const selectStoresConnect = (state) => state.wizard.storesConnect;
export const selectAccountSelectionStep = (state) =>
  state.wizard.accountSelectionStep;
export const selectAdditionalOptionsStep = (state) =>
  state.wizard.additionalOptionsStep;
export const selectDemoProgress = (state) => state.wizard.demoProgress;
export const selectNotification = (state) => state.wizard.notificationData;
export const selectFromTo = (state) => state.wizard.fromTo;
export const selectEmptyMapping = (state) => state.wizard.emptyMapping;
export const selectFromToParam = (state) => state.wizard.fromToParam;
export const selectGlobalNotification = (state) =>
  state.wizard.globalNotification;

export const selectConnectionSkip = (state) => state.wizard.skipConnection;

export const selectFirstFromTo = (state) => state.wizard.firstFromTo;

export const { setDemoVideo } = wizardCreateSlice.actions;

export default wizardCreateSlice.reducer;
