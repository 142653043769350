import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Intercom, { IntercomAPI } from "react-intercom";

import { useAuth } from "../../hooks/useAuth";
import { usePublicConfig } from "../../hooks/usePublicConfig";

const IntercomProvider = ({ children }) => {
  const { user } = useAuth();
  const { env } = usePublicConfig();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === "#showchat") {
      IntercomAPI("show");
    }
  }, [hash]);

  useEffect(() => {
    const migrationId = "22222";

    window.dataLayer = window.dataLayer || [];

    if (user?.id) {
      window.dataLayer.push({
        event: "UserIdEvent",
        userId: user?.id,
      });
    }

    if (migrationId) {
      window.dataLayer.push({
        event: "MigrationIdEvent",
        migrationId,
      });
    }
  }, [user?.id]);

  return (
    <>
      <Intercom
        appID={env?.intercom_app_id ?? process.env?.REACT_APP_INTERCOM_ID}
        environment={env?.hostEnv}
        user_hash={user?.intercom_user_hash}
        user_id={user?.id}
        email={user?.email}
        name={user?.firstname}
        balance={user?.balance}
        discount={user?.discount}
        last_migration_status={user?.last_migration_status}
        new_user={!user?.any_completed_count}
        partner={user?.partner ? "Y" : "N"}
        role={user?.business_role}
      />

      {children}
    </>
  );
};

export default IntercomProvider;
